import { CSSProperties } from "react";
import facepaint from "facepaint";

import { Theme, PropsWithTheme, theme } from "./theme";
import { Spacing, formatBg, MarginProps, PaddingProps } from "./helpers";

export const breakpoints = [600, 1024, 1440, 1920];

export const mq = facepaint(
  breakpoints.map((bp) => `@media(min-width: ${bp + 1}px)`),
);

export type BreakPoints = "0" | "1" | "2" | "3";

export type Responsive<T> = T[] | T | { [key in BreakPoints]?: T };

export type ResponsiveSpacing = Responsive<Spacing>;

export type ResponsiveSizes =
  | string[]
  | number[]
  | "100%"
  | "25%"
  | "75%"
  | number
  | CSSProperties["width"]
  | { [key in BreakPoints]?: string | number };

type ResponsiveDisplay =
  | CSSProperties["display"]
  | { [key in BreakPoints]?: CSSProperties["display"] };

const objectToArray = <T>(
  object: object,
  defaultValue?: string | number,
): T[] => {
  const getNewArr = (key: string, arr: any[], value: string) => {
    let i = Number(key);
    while (key && i < breakpoints.length) {
      arr[i] = value;
      i++;
    }
    return arr;
  };

  // @ts-ignore
  return Object.entries(object).reduce((arr, [key, value]) => {
    if (!arr.length) {
      const newArr = new Array(breakpoints.length).fill(defaultValue || null);
      return getNewArr(key, newArr, value);
    }

    return getNewArr(key, arr, value);
  }, []);
};

export const formatProp = <T>(
  prop: unknown,
  defaultValue?: string | number,
): T[] => {
  if (Array.isArray(prop)) return prop;

  if (prop instanceof Object) {
    const arr = objectToArray<T>(prop, defaultValue);
    return arr;
  }

  return new Array(breakpoints.length).fill(prop || defaultValue);
};

export const formatPropSpace = (
  prop: unknown,
  defaultValue?: string | number,
): (string | number)[] => {
  if (Array.isArray(prop))
    return prop.map((p) => theme.spacing[p as keyof Theme["spacing"]]);

  if (prop instanceof Object) {
    const arr = objectToArray(prop, defaultValue);
    return arr.map((p) => theme.spacing[p as keyof Theme["spacing"]]);
  }

  return new Array(breakpoints.length)
    .fill(prop || defaultValue)
    .map((p) => theme.spacing[p as keyof Theme["spacing"]]);
};

export interface ResponsiveProps {
  display?: ResponsiveDisplay;
  textAlign?: Responsive<CSSProperties["textAlign"]>;
  width?: ResponsiveSizes;
  height?: ResponsiveSizes;
  hide?: boolean[] | { [key in BreakPoints]?: boolean };
  bg?: string | string[];
  maxW?: ResponsiveSizes;
  maxH?: ResponsiveSizes;
  minW?: ResponsiveSizes;
  minH?: ResponsiveSizes;
  position?: CSSProperties["position"] | CSSProperties["position"][];
  gap?: ResponsiveSpacing;
  gapX?: ResponsiveSpacing;
  gapY?: ResponsiveSpacing;
  gridTemplate?: Responsive<CSSProperties["gridTemplate"]>;
  templateCols?: Responsive<CSSProperties["gridTemplateColumns"]>;

  // Same as MarginProps but with option to be an array
  m?: ResponsiveSpacing;
  mT?: ResponsiveSpacing;
  mB?: ResponsiveSpacing;
  mL?: ResponsiveSpacing;
  mR?: ResponsiveSpacing;
  mX?: ResponsiveSpacing;
  mY?: ResponsiveSpacing;

  // Same as PaddingProps but with option to be an array
  p?: ResponsiveSpacing;
  pT?: ResponsiveSpacing;
  pB?: ResponsiveSpacing;
  pL?: ResponsiveSpacing;
  pR?: ResponsiveSpacing;
  pX?: ResponsiveSpacing;
  pY?: ResponsiveSpacing;
}

const fillDefaultValueProp = (
  prop: any[],
  options: { default?: string; newValue?: string },
) => prop.map((p) => (p ? options.newValue || p : options.default));

const formatHiddenProp = (prop: ResponsiveProps["hide"]) => {
  const visibilityOptions = {
    default: "inherit",
    newValue: "hidden",
  };
  const positionOptions = {
    default: "inherit",
    newValue: "absolute",
  };

  if (Array.isArray(prop)) {
    return {
      visibility: fillDefaultValueProp(prop, visibilityOptions),
      position: fillDefaultValueProp(prop, positionOptions),
    };
  }

  if (prop instanceof Object) {
    const arr = objectToArray<boolean>(prop);
    return {
      visibility: fillDefaultValueProp(arr, visibilityOptions),
      position: fillDefaultValueProp(arr, positionOptions),
    };
  }

  return {};
};

const getMarginOrPadding = (
  prop: keyof MarginProps | keyof PaddingProps,
): string =>
  // @ts-ignore
  ({
    m: "margin",
    mT: "marginTop",
    mR: "marginRight",
    mB: "marginBottom",
    mL: "marginLeft",
    p: "padding",
    pT: "paddingTop",
    pR: "paddingRight",
    pB: "paddingBottom",
    pL: "paddingLeft",
  }[prop]);

type ReduceFormatSpacing = [
  keyof MarginProps | keyof PaddingProps,
  ResponsiveSpacing,
];

type ReduceFormatGaps = ["gap" | "gapX" | "gapY", ResponsiveSpacing];

const formatGaps = (gaps: object, themeSpacing: Theme["spacing"]) => {
  return Object.entries(gaps).reduce(
    // @ts-ignore
    (obj, [prop, spacingValue]: ReduceFormatGaps) => {
      if (spacingValue) {
        const value =
          typeof spacingValue !== "string"
            ? formatProp<Spacing>(spacingValue).map(
                (s) => `${themeSpacing[s]} !important`,
              )
            : `${themeSpacing[spacingValue]} !important`;

        if (prop === "gap") {
          // @ts-ignore
          obj.gap = value;
          return obj;
        }
        if (prop === "gapY") {
          // @ts-ignore
          obj.rowGap = value;
          return obj;
        }
        if (prop === "gapX") {
          // @ts-ignore
          obj.columnGap = value;
          return obj;
        }
      }

      return obj;
    },
    {},
  );
};

const formatSpacing = (spacing: object, themeSpacing: Theme["spacing"]) => {
  return Object.entries(spacing).reduce(
    // @ts-ignore
    (obj, [prop, spacingValue]: ReduceFormatSpacing) => {
      if (spacingValue) {
        const value =
          typeof spacingValue !== "string"
            ? formatProp<Spacing>(spacingValue).map(
                (s) => `${themeSpacing[s]} !important`,
              )
            : `${themeSpacing[spacingValue]} !important`;

        if (prop === "mX") {
          // @ts-ignore
          obj.marginLeft = value;
          // @ts-ignore
          obj.marginRight = value;
          return obj;
        }
        if (prop === "mY") {
          // @ts-ignore
          obj.marginTop = value;
          // @ts-ignore
          obj.marginBottom = value;
          return obj;
        }
        if (prop === "pX") {
          // @ts-ignore
          obj.paddingLeft = value;
          // @ts-ignore
          obj.paddingRight = value;
          return obj;
        }
        if (prop === "pY") {
          // @ts-ignore
          obj.paddingTop = value;
          // @ts-ignore
          obj.paddingBottom = value;
          return obj;
        }

        // @ts-ignore
        obj[getMarginOrPadding(prop)] = value;
      }

      return obj;
    },
    {},
  );
};

export const responsiveStyling =
  (additionalStyles?: object) =>
  ({
    display,
    width,
    height,
    hide,
    bg,
    maxW,
    maxH,
    minW,
    minH,
    position,
    textAlign,
    gap,
    gapX,
    gapY,
    templateCols,
    ...spacingProps
  }: PropsWithTheme<ResponsiveProps>) => {
    const hiddenProps = formatHiddenProp(hide);
    const styles = additionalStyles || {};
    const background = bg
      ? typeof bg === "string"
        ? formatBg(bg)
        : bg?.map((b) => formatBg(b))
      : null;

    const spacing = {
      m: spacingProps.m,
      mT: spacingProps.mT,
      mR: spacingProps.mR,
      mB: spacingProps.mB,
      mL: spacingProps.mL,
      mX: spacingProps.mX,
      mY: spacingProps.mY,
      p: spacingProps.p,
      pT: spacingProps.pT,
      pR: spacingProps.pR,
      pB: spacingProps.pB,
      pL: spacingProps.pL,
      pX: spacingProps.pX,
      pY: spacingProps.pY,
    };

    return mq(
      {
        ...(textAlign && {
          textAlign: formatProp(textAlign),
        }),
        ...formatSpacing(spacing, theme.spacing),
        ...formatGaps({ gap, gapX, gapY }, theme.spacing),
        ...(display && {
          display: formatProp<CSSProperties["display"]>(display, "block"),
        }),
        ...(position && {
          position: formatProp<CSSProperties["position"]>(position, "relative"),
        }),
        ...(textAlign && {
          textAlign: formatProp(textAlign),
        }),
        ...(maxW && {
          maxWidth: formatProp<number>(maxW),
        }),
        ...(maxH && {
          maxHeight: formatProp<number>(maxH),
        }),
        ...(minW && {
          minWidth: formatProp<number>(minW),
        }),
        ...(minH && {
          minHeight: formatProp<number>(minH),
        }),
        ...(background && {
          background: formatProp<string>(background, "initial"),
        }),
        ...(width && {
          width: formatProp<string | number>(width, "auto"),
        }),
        ...(height && {
          height: formatProp<string | number>(height, "auto"),
        }),
        ...(templateCols && {
          gridTemplateColumns:
            formatProp<CSSProperties["gridTemplateColumns"]>(templateCols),
        }),
        ...hiddenProps,
        ...styles,
      },
      { overlap: true },
    );
  };

interface ResponsivePropWithRgxProps<T> {
  prop: string;
  value: T | { [key in BreakPoints]?: string | T };
  rgx?: string;
}

export const responsivePropWithRgx = <T>({
  prop,
  value,
  rgx, // find value between ##. like: #value#
}: ResponsivePropWithRgxProps<T>) => {
  if (Array.isArray(value)) {
    return breakpoints.map((bp, i) => {
      if (i === 0)
        return `
          ${prop}: ${rgx ? rgx.replace(/#.*#/, value[i]) : value[i]};
        `;

      if (value[i])
        return `
          @media (min-width: ${bp}px) {
            ${prop}: ${rgx ? rgx.replace(/#.*#/, value[i]) : value[i]};
          }
        `;
    });
  }

  return `
      ${prop}: ${rgx ? rgx.replace(/#.*#/, value as string) : value};
    `;
};

export const responsiveGroupStyles = (
  styles: (string | (string | undefined)[] | undefined)[],
) => {
  return breakpoints.map((bp, i) => {
    if (Array.isArray(styles[0])) {
      if (i === 0) return styles[0][i];

      return styles[0][i];
    }

    if (i === 0) return styles[0];

    if (styles[i])
      return `
    @media (min-width: ${bp}px) {
      ${styles[i]};
    }
  `;
  });
};
