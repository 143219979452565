import { CSSProperties } from "react";
import { css } from "@emotion/core";
import { invariant } from "ts-invariant";
import { Theme, PropsWithTheme, theme, ColorDotWeight } from "./theme";

export const getColor = (color?: keyof Theme["colors"], weight?: string) => {
  if (!color) color = "primary";
  if (!weight) weight = "500";

  // @ts-ignore
  return theme?.colors?.[color]?.[weight];
};

/* 
  Receives a time like "24.5" and formats to: "24h 30m"
  When above 100 hours, it rounds up, else it returns the hours with minutes
*/
export const formatHours = (time: string | number) => {
  const timeNumber = Number(time);
  const hour = Math.floor(timeNumber);
  const minutes = Math.round((timeNumber - hour) * 60);

  if (time === 0) return "0h";

  if (timeNumber > 100) {
    return `${Math.ceil(timeNumber)}h`;
  }

  return `${hour}h ${minutes}m`;
};

export const getColorDotWeight = (color: ColorDotWeight) => {
  if (!color) color = "primary.500";

  const [colorName, colorWeight] = color.split(".") as Split<
    ColorDotWeight,
    "."
  >;

  return theme.colors[colorName][colorWeight];
};

type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends ""
  ? []
  : // eslint-disable-next-line prettier/prettier
  S extends `${infer T}${D}${infer U}`
  ? [T, ...Split<U, D>]
  : [S];

export type Spacing = keyof Theme["spacing"] | "auto";

export type ColorProps = {
  color?: keyof Theme["colors"];
  colorWeight?: string | number;
};

export type BgProps = { bg?: string };

export type CursorProps = {
  cursor?: CSSProperties["cursor"];
};

export type PositionProps = {
  position?:
    | "absolute"
    | "fixed"
    | "relative"
    | "sticky"
    | "static"
    | "unset"
    | "initial"
    | "inherit";
};

export type MaxMinSizeProps = {
  maxW?: number | Spacing;
  maxH?: number | Spacing;
  minW?: number | Spacing;
  minH?: number | Spacing;
};

export const dynamicColor = ({
  theme: _theme,
  color = "primary",
  colorWeight = 500,
}: PropsWithTheme<ColorProps>) => {
  // @ts-ignore
  const formattedColor = theme.colors[color][colorWeight];
  invariant(formattedColor, "Invalid color");
  return css`
    color: ${formattedColor};
  `;
};

export const formatBg = (bg: string) => {
  const [name = "primary", weight = "500"] = bg.split(".");
  if (name === "gradient") {
    // @ts-ignore
    const gradient = theme.gradients[weight];
    invariant(
      gradient,
      `Invalid bg gradient "${name}.${weight}" does not exist on the theme`,
    );
    return gradient;
  }
  // @ts-ignore
  const color = theme.colors[name];
  invariant(color, `Invalid bg color ${name} does not exist on the theme`);
  return color[weight];
};

export const dynamicBg = ({ bg }: PropsWithTheme<BgProps>) => {
  if (!bg) return ``;
  const color = formatBg(bg);
  return css`
    background-color: ${color};
  `;
};

export interface MarginProps {
  m?: Spacing;
  mT?: Spacing;
  mB?: Spacing;
  mL?: Spacing;
  mR?: Spacing;
  mX?: Spacing;
  mY?: Spacing;
}

export interface PaddingProps {
  p?: Spacing;
  pT?: Spacing;
  pB?: Spacing;
  pL?: Spacing;
  pR?: Spacing;
  pX?: Spacing;
  pY?: Spacing;
}

// @ts-ignore
const getDynamicSize = (prop?: Spacing | number, spacing): string => {
  if (!prop) return "initial";
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(prop))) return spacing[prop];
  return `${prop}px`;
};

export const dynamicMaxMinSize = ({
  maxW,
  maxH,
  minW,
  minH,
  theme: { spacing },
}: PropsWithTheme<MaxMinSizeProps>) => css`
  ${ternary(maxW, `max-width: ${getDynamicSize(maxW, spacing)}`)};
  ${ternary(maxH, `max-height: ${getDynamicSize(maxH, spacing)}`)};

  ${ternary(minW, `min-width: ${getDynamicSize(minW, spacing)}`)};
  ${ternary(minH, `min-height: ${getDynamicSize(minH, spacing)}`)};
`;

export const dynamicPadding = ({
  p,
  pT,
  pB,
  pL,
  pR,
  pX,
  pY,
}: PropsWithTheme<PaddingProps>) => css`
  ${ternary(
    p,
    `padding: ${
      // @ts-ignore
      theme.spacing[p]
    }`,
  )};

  ${ternary(
    pX,
    `padding-left: ${
      // @ts-ignore
      theme.spacing[pX]
    }`,
  )};
  ${ternary(
    pX,
    `padding-right: ${
      // @ts-ignore
      theme.spacing[pX]
    }`,
  )};

  ${ternary(
    pY,
    `padding-top: ${
      // @ts-ignore
      theme.spacing[pY]
    }`,
  )};
  ${ternary(
    pY,
    `padding-bottom: ${
      // @ts-ignore
      theme.spacing[pY]
    }`,
  )};

  ${ternary(
    pT,
    `padding-top: ${
      // @ts-ignore
      theme.spacing[pT]
    }`,
  )};
  ${ternary(
    pL,
    `padding-left: ${
      // @ts-ignore
      theme.spacing[pL]
    }`,
  )};
  ${ternary(
    pR,
    `padding-right: ${
      // @ts-ignore
      theme.spacing[pR]
    }`,
  )};
  ${ternary(
    pB,
    `padding-bottom: ${
      // @ts-ignore
      theme.spacing[pB]
    }`,
  )};
`;

export const dynamicMargin = ({
  m,
  mT,
  mB,
  mL,
  mR,
  mX,
  mY,
}: PropsWithTheme<MarginProps>) => css`
  ${ternary(
    m,
    `margin: ${
      // @ts-ignore
      theme.spacing[m]
    }`,
  )};

  ${ternary(
    mX,
    `margin-left: ${
      // @ts-ignore
      theme.spacing[mX]
    }`,
  )};
  ${ternary(
    mX,
    `margin-right: ${
      // @ts-ignore
      theme.spacing[mX]
    }`,
  )};

  ${ternary(
    mY,
    `margin-top: ${
      // @ts-ignore
      theme.spacing[mY]
    }`,
  )};
  ${ternary(
    mY,
    `margin-bottom: ${
      // @ts-ignore
      theme.spacing[mY]
    }`,
  )};

  ${ternary(
    mT,
    `margin-top: ${
      // @ts-ignore
      theme.spacing[mT]
    }`,
  )};
  ${ternary(
    mL,
    `margin-left: ${
      // @ts-ignore
      theme.spacing[mL]
    }`,
  )};
  ${ternary(
    mR,
    `margin-right: ${
      // @ts-ignore
      theme.spacing[mR]
    }`,
  )};
  ${ternary(
    mB,
    `margin-bottom: ${
      // @ts-ignore
      theme.spacing[mB]
    }`,
  )};
`;

const ternary = <T extends {}>(p: any, s: T) => (p ? s : "");

export const getListOfHoursByInterval = (interval = 30) => {
  const x = interval;
  const times = [];
  let tt = 0;

  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60);
    const mm = tt % 60;
    times[i] = `${`0${hh}`.slice(-2)}:${`0${mm}`.slice(-2)}`;
    tt += x;
  }

  return times;
};

export const getListOfMonthsNumber = () => {
  const arr = Array<number>();
  for (let i = 1; i < 32; i++) {
    arr.push(i);
  }
  return arr;
};

export const calcPercentage = (value: number, total: number) =>
  Math.round((value / total) * 100);
